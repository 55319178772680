import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import React from "react";
import { Flex, Tr, Td, Text, Tooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { NumberStyler } from "../../../helper/NumberStyler";
import { useValueHoldings } from "../../../store/useValueHoldings";
import { useHoldingStats } from "../../../store/useHoldingStats";
import { AssetHolding } from "../../../types/wallet/wallet";
import { ImageWithMissingSrc } from "../../atoms/ImageWithMissingSrc";
import { SkeletonRect } from "../../atoms/Skeletons";

export const HoldingTableRow = ({
  holding,
  isLoading,
}: {
  holding: AssetHolding;
  isLoading: boolean;
}): JSX.Element => {
  const { isLoading: holdingsValueLoading } = useValueHoldings();

  const marketValue = holding.marketValue;
  const { gain, roi, roiInfinity } = useHoldingStats({
    marketValue,
    cost: holding.cost,
  });

  return (
    <Tr h="60px" borderWidth="0 0 1px 0" borderColor="white.500">
      <TableCell>
        <Flex direction="row" alignItems="center" minW="120px">
          <ImageWithMissingSrc
            src={holding.asset.image}
            pr="10px"
            w="50px"
            isLoading={isLoading}
          />
          <SkeletonRect isLoaded={!isLoading}>
            <Tooltip label={getAssetLongName(holding.asset)}>
              <Text
                textAlign="center"
                color="#4D4D4D"
                fontSize="0.875rem"
                noOfLines={2}
              >
                {getAssetShortName(holding.asset)}
              </Text>
            </Tooltip>
          </SkeletonRect>
        </Flex>
      </TableCell>
      <TableCell>
        <Flex maxW="180px" pr="10px">
          <SkeletonRect isLoaded={!isLoading}>
            <NumberStyler
              assetInfo={holding.asset}
              num={holding.balance ?? 0}
              negativeColored
              smartRound
            />
          </SkeletonRect>
        </Flex>
      </TableCell>
      <MergeRightTableCell>
        <MergeRightFlex>
          <SkeletonRect isLoaded={!isLoading}>
            <NumberStyler num={holding.cost} unit="currency" />
          </SkeletonRect>
        </MergeRightFlex>
      </MergeRightTableCell>
      <MergeRightTableCell>
        <MergeRightFlex>
          <SkeletonRect isLoaded={!(isLoading || holdingsValueLoading)}>
            <NumberStyler num={marketValue} unit="currency" negativeColored />
          </SkeletonRect>
        </MergeRightFlex>
      </MergeRightTableCell>
      <MergeRightTableCell>
        <MergeRightFlex>
          <SkeletonRect isLoaded={!(isLoading || holdingsValueLoading)}>
            <NumberStyler num={gain} unit="currency" colored />
          </SkeletonRect>
        </MergeRightFlex>
      </MergeRightTableCell>
      <MergeRightTableCell>
        <MergeRightFlex>
          <SkeletonRect isLoaded={!(isLoading || holdingsValueLoading)}>
            {roiInfinity ?
              <Text fontSize="0.875rem" color="green.500">
                &gt; 999.99%
              </Text>
            : <NumberStyler num={roi} unit="percentage" colored />}
          </SkeletonRect>
        </MergeRightFlex>
      </MergeRightTableCell>
    </Tr>
  );
};

const TableCell = styled(Td)`
  padding: 0 0 0 24px;
  margin: 0;
`;

const MergeRightTableCell = styled(Td)`
  padding: 0 24px 0 0;
`;

const MergeRightFlex = styled(Flex)`
  justify-content: flex-end;
  margin-right: 5px;
`;
