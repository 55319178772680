import {
  ChevronUpIcon,
  ChevronDownIcon,
  TriangleUpIcon,
  TriangleDownIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  Text,
  HStack,
  VStack,
  Icon,
  InputProps,
  Input,
  IconButton,
  Button,
  TextProps,
  useClipboard,
} from "@chakra-ui/react";
import {
  sumWithDecimal,
  sumDecimalWithFunction,
  Decimal,
} from "@syla/shared/decimal";
import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { getShortContractAddress } from "@syla/shared/helpers/assets/getShortContractAddress";
import { formatDate } from "@syla/shared/helpers/formatting";
import { SYLA_EARLIEST_EVENT_DATE } from "@syla/shared/types/helpers/constants";
import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import { SyncState } from "@syla/shared/types/models/WalletBase";
import { StatusOption } from "@syla/shared/types/requests/GetGroupsRequest";
import { HoldingDetails as HoldingDetailsBase } from "@syla/shared/helpers/getHoldingsTableData";
import {
  Column,
  Table as TanstackTable,
  FilterFn,
  Row,
  createColumnHelper,
  filterFns,
  sortingFns,
  SortingFn,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import { MdLoop } from "react-icons/md";
import { NumberStyler } from "../../../helper/NumberStyler";
import { sortAssetHoldings } from "../../../helper/wallet/sortAssetHoldings";
import { ReactComponent as HelpCircle } from "../../../images/icons/helpCircle.svg";
import { NavProps, Route } from "../../../routers/navigator";
import { reSyncWallets } from "../../../store/actions/reSyncWallets";
import { AssetHolding } from "../../../types/wallet/wallet";
import { StdVStack } from "../../atoms/Containers";
import { ImageWithMissingSrc } from "../../atoms/ImageWithMissingSrc";
import { RenderIcons } from "../../atoms/RenderIcons";
import { SkeletonRect } from "../../atoms/Skeletons";
import { TooltipWrapper } from "../../atoms/TooltipWrapper";

export enum Columns {
  expand = "expand",
  dataSource = "dataSource",
  address = "address",
  asset = "asset",
  balance = "balance",
  // sourceBalance = "sourceBalance",
  // balanceDiff = "balanceDiff",
  marketValue = "marketValue",
  lastImport = "lastImport",
  actions = "actions",
}

export type ColumnDef = {
  name: string;
  description?: string;
  optional?: { defaultVisible: boolean };
};

export const columnDefs: Record<Columns, ColumnDef> = {
  dataSource: { name: "Data Source" },
  asset: { name: "Asset" },
  balance: { name: "Calculated Balance" },
  marketValue: {
    name: "Calculated Balance Value",
    optional: { defaultVisible: false },
  },
  lastImport: { name: "Last Imported", optional: { defaultVisible: true } },
  address: { name: "Wallet Addresses", optional: { defaultVisible: false } },
  expand: { name: "Expand / Collapse" },
  actions: { name: "" },
};

const nonExpandColumns = [Columns.dataSource, Columns.address];
export const currentDateOnlyColumns = [
  // Columns.sourceBalance,
  // Columns.balanceDiff,
  Columns.lastImport,
];

export function getBalancesTableColumns({
  accountId,
  marketValueLoading,
  navigate,
  balanceDate,
}: {
  accountId: string;
  marketValueLoading: boolean;
  navigate: (props: NavProps) => void;
  balanceDate: Date | undefined;
}) {
  const columnHelper = createColumnHelper<HoldingDetails>();

  const columns = [
    columnHelper.display({
      id: Columns.expand,
      header: ({ table }) => (
        <TooltipWrapper
          tooltip={"Expand/Collapse All"}
          tooltipProps={{ openDelay: 500 }}
        >
          <IconButton
            aria-label="Expand/Collapse"
            variant="ghost"
            icon={
              table.getIsSomeRowsExpanded() ?
                <ChevronUpIcon boxSize={6} />
              : <ChevronDownIcon boxSize={6} />
            }
            onClick={() => {
              table.toggleAllRowsExpanded(!table.getIsSomeRowsExpanded());
            }}
          />
        </TooltipWrapper>
      ),
      aggregatedCell: ({ row }) => (
        <IconButton
          aria-label="Expand/Collapse"
          variant="ghost"
          icon={
            row.getIsExpanded() ?
              <ChevronUpIcon boxSize={6} />
            : <ChevronDownIcon boxSize={6} />
          }
        />
      ),
    }),
    columnHelper.accessor((d) => d.wallet, {
      id: Columns.dataSource,
      meta: {
        name: columnDefs[Columns.dataSource].name,
      },
      getGroupingValue: (row) => row.wallet.id,
      filterFn: customFilter(
        filterFns.arrIncludesSome,
        (value: HoldingDetails["wallet"]) => [value.id],
      ),
      sortingFn: customSort(
        sortingFns.alphanumeric,
        (row) => row.original.wallet.name,
      ),
      header: ({ column }) => (
        <HeaderLeftAlign>
          <SortableHeader column={column}>
            <HeaderText>Data Source</HeaderText>
          </SortableHeader>
        </HeaderLeftAlign>
      ),
      cell: ({ row }) => (
        <HStack>
          <HStack spacing={0}>
            <ImageWithMissingSrc
              src={row.original.wallet.image}
              alt="data source"
              pr="10px"
              w="35px"
              noPaddingIcon
            />
            <VStack alignItems="flex-start" spacing={1}>
              <TText>{row.original.wallet.name}</TText>
              {row.original.wallet.canSync &&
                row.original.wallet.syncState == SyncState.InProgress && (
                  <HStack spacing={1}>
                    <Icon color="yellow.600" as={MdLoop} />
                    <Text fontSize="xs">syncing</Text>
                  </HStack>
                )}
            </VStack>
          </HStack>
          {row.original.wallet.id == "missing" && (
            <TooltipWrapper tooltip="Missing Balances are assets you’ve classified as still owning, but there is no record of where they are currently located. They occur when you classify a transaction as Transfer Out without the matching Transfer In of the same amount, or vice-versa.">
              <Icon as={HelpCircle} color="gray.400" boxSize="1.2em" />{" "}
            </TooltipWrapper>
          )}
        </HStack>
      ),
      aggregationFn: "unique",
      aggregatedCell: ({ getValue, row }) => {
        if (row.getIsExpanded()) return null;

        const values = getValue() as unknown as (
          | HoldingDetails["wallet"]
          | undefined
        )[];
        return (
          <HStack>
            <RenderIcons
              assets={withoutNullable(values).map((v) => ({
                image: v.image,
              }))}
              size="xs"
              qty={4}
              noPaddingIcon
            />
            ;
          </HStack>
        );
      },
    }),
    columnHelper.accessor((d) => d.wallet.address, {
      id: Columns.address,
      meta: {
        name: columnDefs[Columns.address].name,
      },
      filterFn: "includesString",
      aggregationFn: "unique",
      sortingFn: customSort(sortingFns.alphanumeric),
      header: ({ column }) => (
        <HeaderLeftAlign>
          <SortableHeader column={column}>
            <HeaderText>{column.columnDef.meta?.["name"]}</HeaderText>
          </SortableHeader>
        </HeaderLeftAlign>
      ),
      aggregatedCell: ({ getValue }) => {
        const value = getValue() as unknown as (string | undefined)[];
        return <WalletAddresses values={value} />;
      },
      cell: () => null,
    }),
    columnHelper.accessor((d) => d.holding, {
      id: Columns.asset,
      meta: {
        name: columnDefs[Columns.asset].name,
      },
      // filter by array of asset ids
      filterFn: customFilter(
        filterFns.arrIncludesSome,
        (value: AssetHolding | undefined) => [value?.asset._id],
      ),
      aggregationFn: "unique",
      getGroupingValue: (row) => row.holding?.asset._id,
      sortingFn: customSort(
        sortingFns.alphanumeric,
        (row) =>
          row.original.holding && getAssetShortName(row.original.holding.asset),
      ),
      header: ({ column }) => (
        <HeaderLeftAlign>
          <SortableHeader column={column}>
            <HeaderText>{column.columnDef.meta?.["name"]}</HeaderText>
          </SortableHeader>
        </HeaderLeftAlign>
      ),
      cell: ({ row }) =>
        (row.original.holding && (
          <HStack spacing={0}>
            <ImageWithMissingSrc
              src={row.original.holding.asset.image}
              pr="10px"
              w="35px"
              isLoading={false}
            />
            <TooltipWrapper
              tooltip={getAssetLongName(row.original.holding.asset)}
            >
              <TText>{getAssetShortName(row.original.holding.asset)}</TText>
            </TooltipWrapper>
          </HStack>
        )) ||
        null,
      aggregatedCell: ({ getValue, row }) => {
        const holdings = getValue() as unknown as (AssetHolding | undefined)[];
        return (
          holdings.at(0) ?
            row.getIsExpanded() ?
              null
            : <RenderIcons
                assets={sortAssetHoldings(withoutNullable(holdings)).map(
                  (holding) => holding.asset,
                )}
                qty={4}
                size="xs"
              />
          : <TText>No assets held</TText>
        );
      },
    }),
    columnHelper.accessor((d) => d.holding?.balance, {
      id: Columns.balance,
      meta: {
        name: columnDefs[Columns.balance].name,
      },
      enableColumnFilter: false,
      sortingFn: customSort(sortingFns.basic, (row) =>
        row.original.holding?.balance.toNumber(),
      ),
      aggregationFn: (columnId, leafRows, childRows) =>
        sumWithDecimal(
          withoutNullable(childRows.map((row) => row.original.holding)),
          "balance",
        ),
      header: ({ column }) => (
        <HeaderLeftAlign>
          <SortableHeader column={column}>
            <HeaderText>{column.columnDef.meta?.["name"]}</HeaderText>
          </SortableHeader>
        </HeaderLeftAlign>
      ),
      cell: ({ row, getValue }) =>
        (row.original.holding && (
          <NumberStyler
            assetInfo={row.original.holding.asset}
            num={getValue()}
            fontSize="0.75rem"
            mr="50px"
            negativeColored
          />
        )) ||
        null,
      aggregatedCell: ({ table, row, getValue }) =>
        table.getColumn(Columns.dataSource)?.getIsGrouped() ?
          null
        : (row.original.holding && (
            <NumberStyler
              assetInfo={row.original.holding.asset}
              num={getValue()}
              fontSize="0.75rem"
              mr="50px"
              negativeColored
            />
          )) ||
          null,
    }),
    // columnHelper.accessor((d) => undefined, {
    //   id: Columns.sourceBalance,
    //   enableColumnFilter: false,
    //   sortingFn: customSort(sortingFns.basic),
    //   header: ({ column }) => (
    //     <HeaderLeftAlign>
    //       <SortableHeader column={column}>
    //         <HeaderText>Reported Balance</HeaderText>
    //       </SortableHeader>
    //     </HeaderLeftAlign>
    //   ),
    //   cell: ({ row, getValue }) =>
    //     (row.original.holding && (
    //       <NumberStyler
    //         assetInfo={row.original.holding.asset}
    //         num={getValue()}
    //         fontSize="0.75rem"
    //         mr="50px"
    //         negativeColored
    //       />
    //     )) ||
    //     null,
    //   aggregatedCell: () => null,
    // }),
    // columnHelper.accessor((d) => undefined, {
    //   id: Columns.balanceDiff,
    //   enableColumnFilter: false,
    //   sortingFn: customSort(sortingFns.basic),
    //   header: ({ column }) => (
    //     <HeaderLeftAlign>
    //       <SortableHeader column={column}>
    //         <HeaderText>Difference</HeaderText>
    //       </SortableHeader>
    //     </HeaderLeftAlign>
    //   ),
    //   cell: ({ row, getValue }) => (
    //     <NumberStyler
    //       assetInfo={row.original.holding?.asset}
    //       num={getValue()}
    //       fontSize="0.75rem"
    //       mr="50px"
    //       negativeColored
    //     />
    //   ),
    //   aggregatedCell: () => null,
    // }),
    columnHelper.accessor((d) => d.holding?.marketValue, {
      id: Columns.marketValue,
      meta: {
        name: columnDefs[Columns.marketValue].name,
      },
      enableColumnFilter: false,
      sortingFn: customSort(sortingFns.basic, (row) =>
        (row.getValue(Columns.marketValue) as Decimal | undefined)?.toNumber(),
      ),
      sortUndefined: "last",
      aggregationFn: aggregateMarketValues,
      header: ({ column }) => (
        <HeaderRightAlign>
          <SortableHeader column={column}>
            <HeaderText>{column.columnDef.meta?.["name"]}</HeaderText>
          </SortableHeader>
        </HeaderRightAlign>
      ),
      cell: ({ getValue }) => (
        <StdVStack alignItems="flex-end">
          <SkeletonRect isLoaded={!marketValueLoading}>
            <NumberStyler
              num={getValue()}
              fontSize="0.75rem"
              unit="currency"
              colored
            />
          </SkeletonRect>
        </StdVStack>
      ),
      aggregatedCell: ({ getValue }) => (
        <StdVStack alignItems="flex-end">
          <SkeletonRect isLoaded={!marketValueLoading}>
            <NumberStyler
              num={getValue()}
              fontSize="0.75rem"
              unit="currency"
              colored
            />
          </SkeletonRect>
        </StdVStack>
      ),
    }),
    columnHelper.accessor((d) => d.wallet.updatedAt, {
      id: Columns.lastImport,
      meta: {
        name: columnDefs[Columns.lastImport].name,
      },
      enableColumnFilter: false,
      // sortDescFirst: false,
      sortingFn: customSort(sortingFns.basic),
      sortUndefined: "last",
      header: ({ column }) => (
        <HeaderLeftAlign>
          <SortableHeader column={column}>
            <HeaderText>{column.columnDef.meta?.["name"]}</HeaderText>
          </SortableHeader>
        </HeaderLeftAlign>
      ),
      cell: () => null,
      aggregationFn: "max",
      aggregatedCell: ({ getValue }) => {
        const value = getValue();
        return <TText>{value && formatDate(value)}</TText>;
      },
    }),
    columnHelper.display({
      id: Columns.actions,
      header: () => null,
      aggregatedCell: ({ row, table }) =>
        (
          table.getColumn(Columns.dataSource)!.getIsGrouped() &&
          row.original.wallet.canSync
        ) ?
          <SyncDataSource accountId={accountId} wallet={row.original.wallet} />
        : null,
      cell: ({ row }) =>
        row.original.holding ?
          <HeaderRightAlign>
            <TooltipWrapper
              tooltip="View transactions"
              tooltipProps={{ openDelay: 500 }}
            >
              <IconButton
                size="xs"
                aria-label="View transactions"
                variant="ghost"
                _hover={{
                  bgColor: "gray.200",
                }}
                icon={<ExternalLinkIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate({
                    accountId,
                    route: Route.Transactions,
                    state: {
                      filter: {
                        dataSource:
                          row.original.wallet.dataSource ?
                            [row.original.wallet.id]
                          : [],
                        asset: [row.original.holding!.asset._id],
                        ledgerType:
                          row.original.wallet.id == "missing" ?
                            [LedgerType.TransferIn, LedgerType.TransferOut]
                          : [],
                        status:
                          row.original.wallet.id == "missing" ?
                            [StatusOption.NeedsReview]
                          : [],
                        date: balanceDate && {
                          range: [SYLA_EARLIEST_EVENT_DATE, balanceDate],
                        },
                      },
                    },
                  });
                }}
              />
            </TooltipWrapper>
          </HeaderRightAlign>
        : null,
    }),
  ];
  return columns;
}

function SortableHeader(props: {
  column: Column<HoldingDetails>;
  children: React.ReactNode;
}) {
  const unselectedColor = "gray.300";
  const isSorted = props.column.getIsSorted();
  return (
    <TooltipWrapper
      tooltip={`Sort by ${props.column.columnDef.meta?.["name"]}`}
      tooltipProps={{ openDelay: 500 }}
    >
      <Button variant="ghost" onClick={props.column.getToggleSortingHandler()}>
        <HStack>
          {props.children}
          <VStack spacing={0} fontSize="xs">
            {["desc", false].includes(isSorted) && (
              <Icon
                color={!isSorted ? unselectedColor : undefined}
                as={TriangleUpIcon}
              />
            )}
            {["asc", false].includes(isSorted) && (
              <Icon
                color={!isSorted ? unselectedColor : undefined}
                as={TriangleDownIcon}
              />
            )}
          </VStack>
        </HStack>
      </Button>
    </TooltipWrapper>
  );
}

const HeaderText = (props: TextProps) => (
  <Text fontSize="xs" fontWeight="bold" {...props} />
);
const HeaderLeftAlign = StdVStack;
const HeaderRightAlign = (props) => (
  <StdVStack alignItems="flex-end" {...props} />
);
const TText = (props: TextProps) => (
  <Text color="grey.600" fontSize="0.75rem" {...props} />
);

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: TanstackTable<HoldingDetails>;
}) {
  const columnFilterValue = column.getFilterValue();

  return (
    <DebouncedInput
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(value) => {
        // expand data source groups when filtered
        if (value && !nonExpandColumns.includes(column.id as Columns)) {
          table.toggleAllRowsExpanded(true);
        }
        column.setFilterValue(value);
      }}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
      debounce={250}
    />
  );
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputProps, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

// sort Portfolio row to the bottom
const customSort =
  (
    baseSort: SortingFn<HoldingDetails>,
    accessor?: (row: Row<HoldingDetails>) => any,
  ) =>
  (rowA: Row<HoldingDetails>, rowB: Row<HoldingDetails>, columnId: string) => {
    if (!rowA.original.wallet.dataSource && rowB.original.wallet.dataSource)
      return 1;
    if (!rowB.original.wallet.dataSource && rowA.original.wallet.dataSource)
      return -1;

    if (accessor)
      return baseSort(
        { ...rowA, getValue: () => accessor(rowA) },
        { ...rowB, getValue: () => accessor(rowB) },
        columnId,
      );

    return baseSort(rowA, rowB, columnId);
  };
const customFilter =
  (
    baseFilter: FilterFn<HoldingDetails>,
    accessor: (value: any) => any,
  ): FilterFn<HoldingDetails> =>
  (row, columnId, filterValue, addMeta) => {
    return baseFilter(
      { ...row, getValue: (columnId) => accessor(row.getValue(columnId)) },
      columnId,
      filterValue,
      addMeta,
    );
  };

function SyncDataSource({
  accountId,
  wallet,
}: {
  accountId: string;
  wallet: HoldingDetails["wallet"];
}) {
  const isSyncing = wallet.syncState == SyncState.InProgress;

  return (
    <HeaderRightAlign>
      <TooltipWrapper
        tooltip="Sync all transactions"
        tooltipProps={{ openDelay: 500 }}
      >
        <IconButton
          size="sm"
          aria-label="Sync all transactions"
          variant="ghost"
          _hover={{
            bgColor: "gray.200",
          }}
          icon={<MdLoop />}
          isLoading={isSyncing}
          onClick={(event) => {
            event.stopPropagation();
            reSyncWallets(accountId, [
              {
                _id: wallet.id,
                customName: wallet.name,
                dataSource: { name: wallet.dataSource ?? "" },
              },
            ]);
          }}
        />
      </TooltipWrapper>
    </HeaderRightAlign>
  );
}

function WalletAddresses({ values }: { values: (string | undefined)[] }) {
  const { fullAddressString, shortAddressString } = useMemo(() => {
    const addresses = withoutNullable(values)
      .map((value) => value.split(",").map((v) => v.trim()))
      .flat();
    const shortAddress = addresses.map(getShortContractAddress);

    return {
      fullAddressString: addresses.join(", "),
      shortAddressString: shortAddress.join(", "),
    };
  }, [values]);

  const { onCopy, hasCopied } = useClipboard(fullAddressString);

  return (
    <TooltipWrapper
      tooltip={!hasCopied ? `Click to copy ${fullAddressString} ` : "Copied"}
      onClick={(e) => {
        e.stopPropagation();
        onCopy();
      }}
    >
      <TText noOfLines={1} maxWidth="14em">
        {shortAddressString}
      </TText>
    </TooltipWrapper>
  );
}

function aggregateMarketValues(columnId, leafRows: Row<HoldingDetails>[]) {
  const marketValues = leafRows.map((row) =>
    row.getValue(Columns.marketValue),
  ) as (Decimal | undefined)[];
  return sumDecimalWithFunction(marketValues, (value) => value);
}

type HoldingDetails = HoldingDetailsBase<string>;
