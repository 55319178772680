import { Image, ImageProps, Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import missingImageIcon from "../../images/thumbnails/missingImageIcon.svg";
import noPaddingMissingImageIcon from "../../images/thumbnails/noPaddingMissingImageIcon.svg";
import { SkeletonCircle } from "./Skeletons";

export const ImageWithMissingSrc = ({
  src,
  isLoading,
  noPaddingIcon = false,
  ...props
}: ImageProps & {
  isLoading?: boolean;
  noPaddingIcon?: boolean;
}): JSX.Element => {
  const fallbackIcon =
    noPaddingIcon ? noPaddingMissingImageIcon : missingImageIcon;

  const [imgSrc, setImgSrc] = useState(src || fallbackIcon);

  useEffect(() => {
    setImgSrc(src || fallbackIcon);
  }, [src, fallbackIcon]);

  if (isLoading) {
    return (
      <Box {...props}>
        <SkeletonCircle />
      </Box>
    );
  }

  return (
    <Image
      {...props}
      src={imgSrc}
      onError={() => {
        if (imgSrc !== fallbackIcon) {
          setImgSrc(fallbackIcon);
        }
      }}
    />
  );
};
