import { Decimal, sumDecimals } from "@syla/shared/decimal";
import { HoldingsValue } from "../../../../helper/wallet/HoldingsValue";
import { AssetHolding } from "../../../../types/wallet/wallet";

export function getAccountHoldingsValue({
  holdingsValue,
  assetHoldings,
}: {
  holdingsValue: HoldingsValue | undefined;
  assetHoldings: AssetHolding[] | undefined;
}): Decimal | undefined {
  if (!assetHoldings || assetHoldings.length === 0) {
    return Decimal.ZERO;
  } else {
    return sumDecimals(
      assetHoldings.map((holding) => {
        const marketValue = holdingsValue?.getAccountAssetValue({
          assetId: holding.asset._id,
        })?.marketValue;
        return marketValue || holding.cost;
      }),
    );
  }
}
