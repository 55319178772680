/**
 * Filters out `null` and `undefined` values from an array.
 *
 * @param array Array of items
 * @returns Array of items with `null` and `undefined` removed.
 */
export const withoutNullable = <T>(array: T[]): NonNullable<T>[] =>
  array.filter(isNotNullable);

/**
 * Type guard to filter out `null` and `undefined` values.
 */
export function isNotNullable<T>(
  value: T | null | undefined,
): value is NonNullable<T> {
  return value !== null && value !== undefined;
}
