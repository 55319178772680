import { Decimal } from "@syla/shared/decimal";
import { useMemo } from "react";

export const useHoldingStats = ({
  cost,
  marketValue,
}: {
  marketValue: Decimal | undefined;
  cost: Decimal | undefined;
}) => {
  return useMemo(() => {
    const roi = calculateRoi(cost, marketValue);
    const gain = calculateGain(cost, marketValue);
    const roiInfinity = (!roi && cost && cost.isZero()) || roi?.gt("999.99");

    return {
      gain,
      roi,
      roiInfinity,
    };
  }, [cost, marketValue]);
};

function calculateGain(cost?: Decimal, value?: Decimal) {
  if (!cost || !value) {
    return undefined;
  }

  return value.minus(cost);
}

function calculateRoi(cost?: Decimal, value?: Decimal) {
  if (!cost || !value) {
    return undefined;
  }

  if (cost.isZero() && value.isZero()) {
    return Decimal.ZERO;
  }

  if (value.isNegative()) {
    return Decimal.from("-100");
  }

  if (cost.isZero()) {
    return undefined;
  }

  return value.minus(cost).div(cost).times("100");
}
