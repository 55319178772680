import { ModelBase } from "./ModelBase";

export interface DataSourceBase<TId> extends ModelBase<TId> {
  /**
   * Unique code to distinguish a data source.
   * Will not always be set due to custom data sources.
   */
  code?: DataSourceCode;
  name: string;
  url?: string;
  description?: string;
  type: DataSourceType;
  enabled?: boolean;
  fileImportStatus?: ImportStatus;
  fileImportInstructions?: string;
  syncImportStatus?: ImportStatus;
  syncImportInstructions?: string;
  syncImportParams?: SyncImportParamsBase;
  syncAuthType?: "oauth" | "vezgo";
  image?: string;
  mappedToCode?: DataSourceCode;
  /** used to lookup information and mappings about this datasource on Crypto Compare */
  ccInternalName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  networks?: Pick<DataSourceBase<TId>, "_id">[];
}

export interface SyncImportParamsBase {
  key: ImportParamBase;
  secret?: ImportParamBase;
  passphrase?: ImportParamBase;
}

export interface ImportParamBase {
  text: string;
  order: number;
}

export enum DataSourceType {
  Exchange = "exchange",
  Blockchain = "blockchain",
  Wallet = "wallet",
  Custom = "custom",
  Import = "import",
}

// data source names
export enum DataSourceCode {
  // exchanges
  AmberApp = "amberapp",
  Ascendex = "ascendex",
  Bamboo = "bamboo",
  BTCC = "btcc",
  Binance = "Binance",
  BingX = "bingx",
  Bitaroo = "Bitaroo",
  Bitbns = "Bitbns",
  Bitfinex = "Bitfinex",
  Bitget = "bitget",
  Bitkub = "Bitkub",
  BitMart = "BitMart",
  BitMEX = "Bitmex",
  Bitrue = "bitrue",
  Bitstamp = "Bitstamp",
  BitTrade = "bit_trade",
  BitTrex = "BitTrex",
  Bitunix = "bitunix",
  BlockchainDotCom = "blockchaincom",
  BlockFi = "BlockFi",
  BlockEarner = "block_earner",
  BTCE = "BTCE",
  BtcMarkets = "BTCMarkets",
  Bybit = "bybit",
  CalebBrown = "caleb_and_brown",
  Celsius = "Celsius",
  Cex = "Cexio",
  Coinbase = "Coinbase",
  CoinbasePro = "coinbase_pro",
  CoinEx = "CoinEx",
  CoinHarbour = "CoinHarbour",
  CoinJar = "CoinJar",
  CoinJarExchange = "CoinJarExchange",
  Cointree = "Cointree",
  CoinSpot = "CoinSpot",
  CoinStash = "CoinStash",
  CryptoDotCom = "Crypto.com",
  CryptoDotComExchange = "crypto_com_exchange",
  Cryptohopper = "cryptohopper",
  CryptoSpend = "cryptospend",
  DeltaExchange = "delta_spot",
  Degate = "degate",
  DigitalSurge = "DigitalSurge",
  Elbaite = "elbaite",
  Ember = "emberfund",
  EtherDelta = "etherdelta",
  EToro = "etoro",
  Finblox = "finblox",
  FinderEarn = "finder_earn",
  FTX = "FTX",
  Gateio = "Gateio",
  Gemini = "Gemini",
  GPIB = "gpib",
  HardBlock = "hardblock",
  HitBTC = "HitBTC",
  HTX = "huobi",
  IGMarkets = "ig_markets",
  IndependentReserve = "IndependentReserve",
  KinesisMoney = "kinesis_money",
  Kraken = "Kraken",
  KuCoin = "KuCoin",
  Ledn = "ledn",
  LROS = "living_room_of_satoshi",
  Luno = "Luno",
  Mercatox = "mercatox",
  MEXC = "mexc",
  MoonPay = "moonpay",
  Nexo = "nexo",
  NexoPro = "nexo-pro",
  NiceHash = "NiceHash",
  OKX = "OKEX",
  Paxful = "paxful",
  Phemex = "phemex",
  Pionex = "pionex",
  Poloniex = "Poloniex",
  PrimeXBT = "primexbt",
  SafeTrade = "SafeCoin",
  SouthXChange = "south_xchange",
  Swyftx = "Swyftx",
  TradeOgre = "trade_ogre",
  Uphold = "uphold",
  Virgo = "virgo",
  Waves = "waves",
  Wealth99 = "wealth99",
  WooX = "wootrade",
  Xeggex = "xeggex",
  XTDotCom = "xtpub",
  YieldApp = "yield_app",
  YouHodler = "you_hodler",
  CoinList = "coinlist",
  CoinListPro = "coinlist-pro",
  RelayPay = "relay-pay",
  Deribit = "deribit",

  // wallets
  Atomic = "atomic_wallet",
  Daedalus = "daedalus",
  Etherscan = "Etherscan",
  Exodus = "Exodus",
  Ledger = "Ledger",
  Metamask = "Metamask",
  Mintscan = "mintscan",
  MyEtherWallet = "MyEtherWallet",
  Qoin = "qoin",
  Trezor = "trezor",
  Vera = "Vera",
  Xaman = "xaman",
  Yoroi = "yoroi",

  // blockchains
  Algorand = "algorand",
  Arbitrum = "arbitrum", // arbitrum one
  Avalanche = "avalanche", // c-chain
  AvalancheXChain = "avalanche-x-chain", // x-chain
  Beam = "beam",
  Bitcoin = "Bitcoin",
  BitcoinCash = "bitcoinCash",
  Blast = "blast",
  BloFin = "blofin",
  CasperNetwork = "casper-network",
  Chiliz = "chiliz_chain",
  Cyber = "cyber",
  Elastos = "elastos",
  ECash = "eCash",
  Litecoin = "litecoin",
  Vechain = "vechain",
  Dash = "dash",
  DFK = "defi_kingdoms_chain",
  Dogecoin = "dogecoin",
  Base = "base",
  BSC = "binance_smart_chain",
  Cardano = "Cardano",
  Celo = "celo",
  Cronos = "cronos_evm",
  Ethereum = "Ethereum",
  EthereumPow = "ethereumpow",
  EthereumClassic = "ethereum_classic",
  Flare = "flare",
  Fraxtal = "fraxtal",
  Flow = "flow",
  FTM = "fantom",
  Gnosis = "gnosis_chain",
  HederaHashgraph = "hedera-hashgraph", // hbar
  Harmony = "harmony",
  Helium = "Helium",
  Kaspa = "kasplex",
  ICON = "icon",
  InternetComputer = "internet-computer",
  EnergyWeb = "energy-web",
  Linea = "linea",
  NearProtocol = "near-protocol",
  Mantle = "mantle",
  Metis = "metis",
  Mint = "mint_chain",
  MinaProtocol = "mina-protocol",
  Mode = "mode_network",
  Moonbeam = "moonbeam",
  MultiversX = "multiversx",
  Optimism = "optimism",
  Polkadot = "polkadot",
  Polygon = "polygon",
  SeiNetwork = "sei-network",
  Solana = "solana",
  Scroll = "scroll",
  Songbird = "songbird",
  Stacks = "stacks",
  Stellar = "stellar",
  Sui = "sui",
  Symbol = "symbol",
  Taiko = "taiko",
  Tezos = "tezos",
  Neo = "neo",
  Zilliqa = "zilliqa",
  Ton = "ton",
  Thorchain = "thorchain",
  Tron = "tron",
  XRP = "xrp",
  Nano = "nano",
  Eos = "eos",
  ZkSyncEra = "zksync_era",
  Zora = "zora_chain",
  BnbBeaconChain = "bnb_beacon_chain",
  Theta = "theta-network",
  Terra = "terra-two", // the new luna terra chain
  TerraClassic = "terra",
  Cosmos = "cosmos", // Cosmos Hub blockchain
  Agoric = "agoric", // BLD
  Akash = "akash",
  Althea = "althea",
  Archway = "archway",
  Atomone = "atomone",
  Axelar = "axelar",
  Band = "band",
  Bitsong = "bitsong",
  Celestia = "celestia",
  Chihuahua = "chihuahua",
  Coreum = "coreum",
  CronosPos = "crypto-org", // this is the original crypto.com / crypto.org chain
  DyDxChain = "dydx-chain", // dydx v4 on cosmos
  Dymension = "dymension",
  Evmos = "evmos",
  FetchAi = "fetchai", // ASI ALLIANCE
  Finschia = "finschia",
  Govgen = "govgen",
  GravityBridge = "gravity-bridge",
  HumansAi = "humans",
  Injective = "injective-chain",
  Iris = "iris",
  Kava = "kava",
  Mantra = "mantra",
  Medibloc = "medibloc",
  Milkyway = "milkyway",
  Neutron = "neutron",
  Noble = "noble",
  Omniflix = "omniflix",
  OnomyProtocol = "onomy-protocol",
  Osmosis = "osmosis",
  Persistence = "persistence",
  Quasar = "quasar",
  Regen = "regen",
  Saga = "saga",
  Secret = "secret",
  Sei = "sei",
  Selfchain = "selfchain",
  Sentinel = "sentinel", // DVPN
  Shentu = "shentu",
  Sommelier = "sommelier",
  StaFi = "stafi",
  Stargaze = "stargaze",
  Stride = "stride",
  Xion = "xion",
  Xpla = "xpla",
  Zetachain = "zeta",
  Iotex = "iotex",
  Juno = "juno",
  KyveNetwork = "kyve-network",
  Nolus = "nolus",
  Acala = "acala",
  Wax = "wax", // WAX Blockchain

  // others
  OpeningBalances = "sylaOpeningBalances",
  Koinly = "koinly",
  CoinTracking = "cointracking",
}

export enum ImportStatus {
  Off = "off",
  Alpha = "alpha",
  Beta = "beta",
  Gold = "gold",
}

export interface DataSourceSupportedInputs {
  sync: ImportStatus | undefined;
  file: ImportStatus | undefined;
}
export const getDataSourceSupportedInputs = ({
  fileImportStatus,
  syncImportStatus,
}: {
  fileImportStatus?: ImportStatus;
  syncImportStatus?: ImportStatus;
}): DataSourceSupportedInputs => ({
  file: (fileImportStatus ?? "off") === "off" ? undefined : fileImportStatus,
  sync: (syncImportStatus ?? "off") === "off" ? undefined : syncImportStatus,
});
