import { Flex, Thead, Tbody, Tr, Th, Table, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Decimal } from "@syla/shared/decimal";
import { AssetType } from "@syla/shared/types/models/AssetBase";
import React from "react";
import { useValueHoldings } from "../../../store/useValueHoldings";

import { AssetHolding, WalletResponse } from "../../../types/wallet/wallet";
import { ContentContainer, StdVStack } from "../../atoms/Containers";
import { EmptyDataMessage } from "../../atoms/EmptyDataMessage";
import { SectionHeading } from "../../atoms/Headings";
import { sortHoldings } from "../dataSource/sortHoldings";
import { HoldingTableRow } from "./HoldingTableRow";

export const HoldingTable = ({
  holdings,
  wallets,
  isLoading,
}: {
  holdings: AssetHolding[] | undefined;
  wallets: WalletResponse[] | undefined;
  isLoading: boolean;
}): JSX.Element => {
  // add market value to holdings
  const { data: holdingsValue } = useValueHoldings();

  // populate wallet holding market values
  for (const holding of holdings ?? []) {
    holding.marketValue =
      holdingsValue?.getAccountAssetValue({
        assetId: holding.asset._id,
      })?.marketValue ?? holding.cost; // if no market value, we use the cost as the last known valuation.
  }

  const holdingsSorted = sortHoldings(holdings ?? []);

  return (
    <ContentContainer display="flex" flexDirection="column">
      <Flex
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        pr="30px"
      >
        <SectionHeading>Portfolio</SectionHeading>
      </Flex>
      <StdVStack overflowX="auto">
        <Table position="relative" flex={1}>
          <Thead h="38px" top="0">
            <Tr>
              <TableHeaderCell>
                <MergeLeftFlex>Asset</MergeLeftFlex>
              </TableHeaderCell>
              <TableHeaderCell>
                <MergeLeftFlex>Balance</MergeLeftFlex>
              </TableHeaderCell>
              <TableHeaderCell>
                <MergeRightFlex>Cost</MergeRightFlex>
              </TableHeaderCell>
              <TableHeaderCell>
                <MergeRightFlex>Current Value</MergeRightFlex>
              </TableHeaderCell>
              <TableHeaderCell>
                <MergeRightFlex>Unrealised Gain</MergeRightFlex>
              </TableHeaderCell>
              <TableHeaderCell>
                <MergeRightFlex>ROI</MergeRightFlex>
              </TableHeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {!holdings || isLoading ?
              [...Array(4)].map((_, idx) => (
                <HoldingTableRow
                  isLoading={true}
                  key={idx}
                  holding={{
                    asset: {
                      _id: "1",
                      code: "DOGE",
                      name: "DogeCoin",
                      type: AssetType.Custom,
                    },
                    balance: Decimal.from("1000"),
                    cost: Decimal.from("10000"),
                  }}
                />
              ))
            : holdingsSorted.map((holding) => (
                <HoldingTableRow
                  key={holding.asset._id}
                  holding={holding}
                  isLoading={isLoading}
                />
              ))
            }
          </Tbody>
        </Table>
        {!isLoading && holdings?.length === 0 && (
          <StdVStack alignItems="center" h="100%">
            {wallets?.length === 0 ?
              <EmptyDataMessage content="Add a data source to see your holdings" />
            : <StdVStack alignItems="center" py="1rem">
                <Text>No assets currently held</Text>
              </StdVStack>
            }
          </StdVStack>
        )}
      </StdVStack>
    </ContentContainer>
  );
};

const TableHeaderCell = styled(Th)`
  padding: 0;
  background-color: #fff;
`;

const MergeLeftFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: #666;
  padding: 10px 0 10px 24px;
  border-width: 1px 0 1px 0;
  border-color: #f5f5f5;
  white-space: nowrap;
`;
const MergeRightFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: #666;
  justify-content: flex-end;
  padding: 10px 26px 10px 0;
  border-width: 1px 0 1px 0;
  border-color: #f5f5f5;
  white-space: nowrap;
`;
