import {
  Flex,
  Text,
  Button,
  HStack,
  VStack,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Alert,
  CloseButton,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  WalletImportMethod,
  SyncState,
  FileImportState,
  SyncWalletErrorDetailCode,
} from "@syla/shared/types/models/WalletBase";
import React, { useMemo } from "react";
import { MdSync, MdOutlineUpload } from "react-icons/md";
import ReactMarkdown from "react-markdown";

import { NumberStyler } from "../../../../../helper/NumberStyler";

import { getSyncErrorProps } from "../../../../../helper/wallet/getSyncErrorProps";
import { sortAssetHoldings } from "../../../../../helper/wallet/sortAssetHoldings";
import { walletStateProps } from "../../../../../helper/walletProps";
import { useReportError } from "../../../../../helpers/useReportError";
import { reSyncWallets } from "../../../../../store/actions/reSyncWallets";
import { updateWallet } from "../../../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import { useValueHoldings } from "../../../../../store/useValueHoldings";
import { WalletResponse } from "../../../../../types/wallet/wallet";
import { StdVStack } from "../../../../atoms/Containers";
import { Divider } from "../../../../atoms/Texts";
import { ContactSupportLink } from "../../../../molecules/ContactSupportLink";
import { getDataSourceStatusProps } from "../../getDataSourceStatusProps";
import { LastSyncedTime } from "../../LastSyncedTime";
import { getWalletHoldingsValue } from "../getWalletHoldingsValue";
import { HoldingTable } from "./HoldingTable";
import { WalletDetails } from "./WalletDetails";

export const DataSourceExpanded = ({
  walletResponse,
  openEditDrawer,
  allowSync,
  allowEdit,
}: {
  walletResponse: WalletResponse;
  allowSync: boolean;
  allowEdit: boolean;
  openEditDrawer?: () => void;
}): JSX.Element => {
  const reportError = useReportError();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { holdingsLoading } = walletStateProps(walletResponse);

  const { data: holdingsValue, isLoading: valueHoldingsLoading } =
    useValueHoldings();

  const totalValue = useMemo(
    () =>
      getWalletHoldingsValue({
        walletWithHoldings: walletResponse,
        holdingsValue,
      }),
    [holdingsValue, walletResponse],
  );

  // skip negative holdings when calculating allocation %
  const allocationTotalValue = useMemo(
    () =>
      holdingsValue?.getWalletTotals({
        walletId: walletResponse._id,
        skipNegative: true,
      })?.marketValue,
    [holdingsValue, walletResponse._id],
  );

  // re-sync wallet handler
  const reSyncWalletHandler = async () => {
    // check if the wallet was imported via api
    if (!allowSync) return;

    reSyncWallets(accountId, [walletResponse]);
  };

  const statusProps = getDataSourceStatusProps(walletResponse);

  const syncErrorDetails =
    walletResponse.syncState == SyncState.Error &&
    walletResponse.syncError &&
    getSyncErrorProps(walletResponse, walletResponse.syncError);

  const fileImportErrorDetails = walletResponse.fileImportState ==
    FileImportState.Error && {
    title: "Last Import Failed",
    message: "Your last import was unsuccessful",
  };

  const syncDisabled =
    walletResponse.syncState == SyncState.InProgress &&
    !walletResponse.vezgoAccountId;
  return (
    <StdVStack bgColor="white.500" padding="20px" spacing={4}>
      {syncErrorDetails && (
        <ErrorSection
          errorDetails={syncErrorDetails}
          context={walletResponse}
          primaryActionButton={
            walletResponse.syncError?.code ==
              SyncWalletErrorDetailCode.INVALID_AUTH && (
              <Button variant="ghost" color="red.500" onClick={openEditDrawer}>
                Fix Access
              </Button>
            )
          }
        />
      )}
      {fileImportErrorDetails && (
        <ErrorSection
          errorDetails={fileImportErrorDetails}
          context={walletResponse}
          onClose={() => {
            // clear import state
            updateWallet(accountId, walletResponse._id, {
              fileImportState: null,
            }).catch(reportError);
          }}
        />
      )}
      <HStack
        spacing={4}
        flexShrink={0}
        flexGrow={1}
        alignItems="flex-start"
        w="100%"
      >
        <Flex flexGrow={1} flexShrink={0} flexBasis="1px" direction="column">
          <TitleText>Status</TitleText>
          <Flex direction="row" alignItems="flex-start" mb="10px">
            {walletResponse.importMethod == WalletImportMethod.API ?
              <MdSync size="1.1rem" color="#0BC196" />
            : <MdOutlineUpload size="1.1rem" color="#2A84FF" />}

            <Flex direction="column" ml="10px">
              <Text color="black.600" fontSize="0.875rem">
                <Text color={statusProps.color} pr="5px" fontSize="0.75rem">
                  {statusProps.status}
                </Text>
                <LastSyncedTime
                  walletResponse={walletResponse}
                  lastUpdated={statusProps.lastUpdated}
                />
              </Text>
            </Flex>
          </Flex>

          {/* only show the sync button if the wallet was imported via api */}
          {allowSync && (
            <RedBtn
              isDisabled={syncDisabled}
              onClick={reSyncWalletHandler}
              w="70px"
              mb="20px"
            >
              Sync
            </RedBtn>
          )}
          <TitleText>Wallet Details</TitleText>
          <WalletDetails walletResponse={walletResponse} />
          {allowEdit && (
            <RedBtn onClick={openEditDrawer} w="100px">
              Edit
            </RedBtn>
          )}
        </Flex>
        <Flex
          flexGrow={2}
          flexShrink={0}
          flexBasis="1px"
          direction="column"
          mx="20px"
        >
          <Flex direction="row">
            <TitleText>Holdings</TitleText>
            <Divider m="0 10px" />
            {holdingsLoading || valueHoldingsLoading ?
              <Text>Calculating...</Text>
            : <NumberStyler
                num={totalValue}
                unit="currency"
                fontWeight="600"
                fontSize="0.875rem"
                negativeColored
              />
            }
          </Flex>
          {!holdingsLoading && walletResponse.holdings && (
            <HoldingTable
              holdings={sortAssetHoldings(walletResponse.holdings)}
              totalHoldingValue={allocationTotalValue}
            />
          )}
        </Flex>
      </HStack>
    </StdVStack>
  );
};

const TitleText = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

const RedBtn = styled(Button)`
  border-color: #ff3600;
  color: #ff3600;
  font-size: 0.75rem;
  font-weight: 600;
  height: 30px;
  background-color: transparent;
  border-width: 1px;
  padding: 0 10px;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    border-color: #ff3600;
  }
  &:active {
    border-color: #ff3600;
    background-color: #ffd7cc;
  }
`;

function ErrorSection({
  errorDetails: { message, title },
  context: {
    dataSource: { name },
  },
  onClose,
  primaryActionButton,
}: {
  errorDetails: { title: string; message?: string };
  context: { dataSource: { name: string } };
  onClose?: React.Dispatch<void>;
  primaryActionButton?: React.ReactNode;
}) {
  return (
    <Alert
      status="error"
      flexDirection="row"
      alignItems="flex-start"
      borderRadius="16px"
      border="1px solid"
      borderColor="red.500"
      // w="fit-content"
    >
      <AlertIcon />
      <VStack alignItems="flex-start" spacing={4}>
        <VStack alignItems="flex-start" spacing={1}>
          <AlertTitle>{title}</AlertTitle>
          {message && (
            <AlertDescription>
              <ReactMarkdown linkTarget="_blank">{message}</ReactMarkdown>
            </AlertDescription>
          )}
        </VStack>

        <HStack>
          {/* Primary Action */}
          {primaryActionButton}

          {/* Request Support */}
          <ContactSupportLink
            supportPrompt={`I had an error syncing my ${name} data source`}
          />
        </HStack>
      </VStack>
      {onClose && (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => onClose()}
        />
      )}
    </Alert>
  );
}
