import { DataSourceCode } from "@syla/shared/types/models/DataSourceBase";

export const buildExplorerUrl = ({
  code,
  txHash,
}: {
  txHash: string;
  code?: DataSourceCode;
}) => {
  const escapedHash = encodeURIComponent(txHash);

  // handle empty string ""
  if (!escapedHash) {
    return undefined;
  }

  // without an explicit data source code it's too unreliable to guess
  // which chain the hash belongs to.
  if (!code) {
    return undefined;
  }

  switch (code) {
    case DataSourceCode.Algorand:
      return `https://allo.info/tx/${escapedHash}`;
    case DataSourceCode.Ethereum:
      return `https://etherscan.io/tx/${escapedHash}`;
    case DataSourceCode.BSC:
      return `https://bscscan.com/tx/${escapedHash}`;
    case DataSourceCode.Celo:
      return `https://celoscan.io/tx/${escapedHash}`;
    case DataSourceCode.Polygon:
      return `https://polygonscan.com/tx/${escapedHash}`;
    case DataSourceCode.FTM:
      return `https://ftmscan.com/tx/${escapedHash}`;
    case DataSourceCode.Cronos:
      return `https://cronoscan.com/tx/${escapedHash}`;
    case DataSourceCode.Moonbeam:
      return `https://moonscan.io/tx/${escapedHash}`;
    case DataSourceCode.Tezos:
      return `https://tzkt.io/${escapedHash}`;
    case DataSourceCode.Cardano:
      return `https://cardanoscan.io/transaction/${escapedHash}`;

    // mintscan urls
    case DataSourceCode.Cosmos:
      return `https://www.mintscan.io/cosmos/tx/${escapedHash}`;
    case DataSourceCode.Akash:
      return `https://www.mintscan.io/akash/tx/${escapedHash}`;
    case DataSourceCode.Injective:
      return `https://www.mintscan.io/injective/tx/${escapedHash}`;
    case DataSourceCode.Agoric:
      return `https://www.mintscan.io/agoric/tx/${escapedHash}`;
    case DataSourceCode.Althea:
      return `https://www.mintscan.io/althea/tx/${escapedHash}`;
    case DataSourceCode.Archway:
      return `https://www.mintscan.io/archway/tx/${escapedHash}`;
    case DataSourceCode.Atomone:
      return `https://www.mintscan.io/atomone/tx/${escapedHash}`;
    case DataSourceCode.Axelar:
      return `https://www.mintscan.io/axelar/tx/${escapedHash}`;
    case DataSourceCode.Band:
      return `https://www.mintscan.io/band/tx/${escapedHash}`;
    case DataSourceCode.Bitsong:
      return `https://www.mintscan.io/bitsong/tx/${escapedHash}`;
    case DataSourceCode.Celestia:
      return `https://www.mintscan.io/celestia/tx/${escapedHash}`;
    case DataSourceCode.Chihuahua:
      return `https://www.mintscan.io/chihuahua/tx/${escapedHash}`;
    case DataSourceCode.Coreum:
      return `https://www.mintscan.io/coreum/tx/${escapedHash}`;
    case DataSourceCode.CronosPos:
      return `https://www.mintscan.io/crypto-org/tx/${escapedHash}`;
    case DataSourceCode.DyDxChain:
      return `https://www.mintscan.io/dydx/tx/${escapedHash}`;
    case DataSourceCode.Dymension:
      return `https://www.mintscan.io/dymension/tx/${escapedHash}`;
    case DataSourceCode.Evmos:
      return `https://www.mintscan.io/evmos/tx/${escapedHash}`;
    case DataSourceCode.FetchAi:
      return `https://www.mintscan.io/fetchai/tx/${escapedHash}`;
    case DataSourceCode.Finschia:
      return `https://www.mintscan.io/finschia/tx/${escapedHash}`;
    case DataSourceCode.Govgen:
      return `https://www.mintscan.io/govgen/tx/${escapedHash}`;
    case DataSourceCode.GravityBridge:
      return `https://www.mintscan.io/gravity-bridge/tx/${escapedHash}`;
    case DataSourceCode.HumansAi:
      return `https://www.mintscan.io/humans/tx/${escapedHash}`;
    case DataSourceCode.Iris:
      return `https://www.mintscan.io/iris/tx/${escapedHash}`;
    case DataSourceCode.Kava:
      return `https://www.mintscan.io/kava/tx/${escapedHash}`;
    case DataSourceCode.Mantra:
      return `https://www.mintscan.io/mantra/tx/${escapedHash}`;
    case DataSourceCode.Medibloc:
      return `https://www.mintscan.io/medibloc/tx/${escapedHash}`;
    case DataSourceCode.Milkyway:
      return `https://www.mintscan.io/milkyway/tx/${escapedHash}`;
    case DataSourceCode.Neutron:
      return `https://www.mintscan.io/neutron/tx/${escapedHash}`;
    case DataSourceCode.Noble:
      return `https://www.mintscan.io/noble/tx/${escapedHash}`;
    case DataSourceCode.Omniflix:
      return `https://www.mintscan.io/omniflix/tx/${escapedHash}`;
    case DataSourceCode.OnomyProtocol:
      return `https://www.mintscan.io/onomy-protocol/tx/${escapedHash}`;
    case DataSourceCode.Osmosis:
      return `https://www.mintscan.io/osmosis/tx/${escapedHash}`;
    case DataSourceCode.Persistence:
      return `https://www.mintscan.io/persistence/tx/${escapedHash}`;
    case DataSourceCode.Quasar:
      return `https://www.mintscan.io/quasar/tx/${escapedHash}`;
    case DataSourceCode.Secret:
      return `https://www.mintscan.io/secret/tx/${escapedHash}`;
    case DataSourceCode.Sei:
      return `https://www.mintscan.io/sei/tx/${escapedHash}`;
    case DataSourceCode.Selfchain:
      return `https://www.mintscan.io/selfchain/tx/${escapedHash}`;
    case DataSourceCode.Sentinel:
      return `https://www.mintscan.io/sentinel/tx/${escapedHash}`;
    case DataSourceCode.Shentu:
      return `https://www.mintscan.io/shentu/tx/${escapedHash}`;
    case DataSourceCode.Sommelier:
      return `https://www.mintscan.io/sommelier/tx/${escapedHash}`;
    case DataSourceCode.StaFi:
      return `https://www.mintscan.io/stafi/tx/${escapedHash}`;
    case DataSourceCode.Stargaze:
      return `https://www.mintscan.io/stargaze/tx/${escapedHash}`;
    case DataSourceCode.Stride:
      return `https://www.mintscan.io/stride/tx/${escapedHash}`;
    case DataSourceCode.Xion:
      return `https://www.mintscan.io/xion/tx/${escapedHash}`;
    case DataSourceCode.Xpla:
      return `https://www.mintscan.io/xpla/tx/${escapedHash}`;
    case DataSourceCode.Zetachain:
      return `https://www.mintscan.io/zeta/tx/${escapedHash}`;

    case DataSourceCode.KyveNetwork:
      return `https://viewblock.io/kyve/tx/${escapedHash}`;
    case DataSourceCode.Iotex:
      return `https://iotexscan.io/tx/${escapedHash}`;
    case DataSourceCode.Flare:
      return `https://flare-explorer.flare.network/tx/${escapedHash}`;
    case DataSourceCode.Songbird:
      return `https://songbird-explorer.flare.network/tx/${escapedHash}`;
    case DataSourceCode.Avalanche:
      return `https://subnets.avax.network/c-chain/tx/${escapedHash}`;
    case DataSourceCode.AvalancheXChain:
      return `https://subnets.avax.network/x-chain/tx/${escapedHash}`;
    case DataSourceCode.Beam:
      return `https://subnets.avax.network/beam/tx/${escapedHash}`;
    case DataSourceCode.Stacks:
      return `https://explorer.hiro.so/txid/${escapedHash}?chain=mainnet`;
    case DataSourceCode.MultiversX:
      return `https://explorer.multiversx.com/transactions/${escapedHash}`;
    case DataSourceCode.Arbitrum:
      return `https://arbiscan.io/tx/${escapedHash}`;
    case DataSourceCode.Optimism:
      return `https://optimistic.etherscan.io/tx/${escapedHash}`;
    case DataSourceCode.Base:
      return `https://basescan.org/tx/${escapedHash}`;
    case DataSourceCode.Blast:
      return `https://blastscan.io/tx/${escapedHash}`;
    case DataSourceCode.Mode:
      return `https://explorer.mode.network/tx/${escapedHash}`;
    case DataSourceCode.Mantle:
      return `https://explorer.mantle.xyz/tx/${escapedHash}`;
    case DataSourceCode.ZkSyncEra:
      return `https://era.zksync.network/tx/${escapedHash}`;
    case DataSourceCode.Gnosis:
      return `https://gnosisscan.io/tx/${escapedHash}`;
    case DataSourceCode.HederaHashgraph:
      return `https://hashscan.io/mainnet/transaction/${escapedHash}`;
    case DataSourceCode.Scroll:
      return `https://scrollscan.com/tx/${escapedHash}`;
    case DataSourceCode.Metis:
      return `https://andromeda-explorer.metis.io/tx/${escapedHash}`;
    case DataSourceCode.Taiko:
      return `https://taikoscan.io/tx/${escapedHash}`;
    case DataSourceCode.Fraxtal:
      return `https://fraxscan.com/tx/${escapedHash}`;
    case DataSourceCode.Zora:
      return `https://explorer.zora.energy/tx/${escapedHash}`;
    case DataSourceCode.Cyber:
      return `https://cyberscan.co/tx/${escapedHash}`;
    case DataSourceCode.DFK:
      return `https://subnets.avax.network/defi-kingdoms/tx/${escapedHash}`;
    case DataSourceCode.Mint:
      return `https://explorer.mintchain.io/tx/${escapedHash}`;
    case DataSourceCode.Solana:
      return `https://solscan.io/tx/${escapedHash}`;
    case DataSourceCode.Symbol:
      return `https://symbol.fyi/transactions/${escapedHash}`;
    case DataSourceCode.InternetComputer:
      return `https://www.icpexplorer.org/#/tx/${escapedHash}`;
    case DataSourceCode.EthereumClassic:
      return `https://etc.blockscout.com/tx/${escapedHash}`;
    case DataSourceCode.Polkadot:
      return `https://polkadot.subscan.io/extrinsic/${escapedHash}`;
    case DataSourceCode.MinaProtocol:
      return `https://minaexplorer.com/transaction/${escapedHash}`;
    case DataSourceCode.CasperNetwork:
      return `https://cspr.live/deploy/${escapedHash}`;
    case DataSourceCode.Chiliz:
      return `https://scan.chiliz.com/tx/${escapedHash}`;
    case DataSourceCode.XRP:
      return `https://xrpscan.com/tx/${escapedHash}`;
    case DataSourceCode.Stellar:
      return `https://stellar.expert/explorer/public/tx/${escapedHash}`;
    case DataSourceCode.Tron:
      return `https://tronscan.org/#/transaction/${escapedHash}`;
    case DataSourceCode.Thorchain:
      return `https://runescan.io/tx/${escapedHash}`;
    case DataSourceCode.Ton:
      return `https://tonscan.org/tx/${escapedHash}`;
    case DataSourceCode.Elastos:
      return `https://ela.elastos.io/tx/${escapedHash}`;
    case DataSourceCode.BnbBeaconChain:
      return `https://explorer.bnbchain.org/tx/${escapedHash}`;
    case DataSourceCode.Waves:
      return `https://wavesexplorer.com/transactions/${escapedHash}`;
    case DataSourceCode.ICON:
      return `https://tracker.icon.community/transaction/${escapedHash}`;
    case DataSourceCode.NearProtocol:
      return `https://nearblocks.io/txns/${escapedHash}`;
    case DataSourceCode.Theta:
      return `https://explorer.thetatoken.org/txs/${escapedHash}`;
    case DataSourceCode.Flow:
      return `https://www.flowscan.io/tx/${escapedHash}`;
    case DataSourceCode.Bitcoin:
      return `https://bitaps.com/${escapedHash}`;
    case DataSourceCode.Litecoin:
      return `https://litecoinblockexplorer.net/tx/${escapedHash}`;
    case DataSourceCode.Dogecoin:
      return `https://blockchair.com/dogecoin/transaction/${escapedHash}`;
    case DataSourceCode.BitcoinCash:
      return `https://blockchair.com/bitcoin-cash/transaction/${escapedHash}`;
    case DataSourceCode.ECash:
      return `https://blockchair.com/ecash/transaction/${escapedHash}`;
    case DataSourceCode.Dash:
      return `https://blockchair.com/dash/transaction/${escapedHash}`;
    case DataSourceCode.Kaspa:
      return `https://kas.fyi/transaction/${escapedHash}`;
    case DataSourceCode.Linea:
      return `https://lineascan.build/tx/${escapedHash}`;
    case DataSourceCode.EthereumPow:
      return `https://www.oklink.com/ethw/tx/${escapedHash}`;
    case DataSourceCode.Harmony:
      return `https://explorer.harmony.one/tx/${escapedHash}`;
    case DataSourceCode.SeiNetwork:
      return `https://www.seiscan.app/pacific-1/txs/${escapedHash}`;
    case DataSourceCode.Vechain:
      return `https://explore.vechain.org/transactions/${escapedHash}`;
    case DataSourceCode.Nano:
      return `https://nanexplorer.com/all/block/${escapedHash}`;
    case DataSourceCode.Eos:
      return `https://eosflare.io/tx/${escapedHash}`;
    case DataSourceCode.Zilliqa:
      return `https://viewblock.io/zilliqa/tx/${escapedHash}`;
    case DataSourceCode.Neo: // legacy neo
      return `https://neo2.neotube.io/transaction/${escapedHash}`;
    case DataSourceCode.TerraClassic:
      return `https://finder.terra.money/classic/tx/${escapedHash}`;
    case DataSourceCode.Terra:
      return `https://finder.terra.money/mainnet/tx/${escapedHash}`;
    case DataSourceCode.EnergyWeb:
      return `https://explorer.energyweb.org/tx/${escapedHash}`;
    case DataSourceCode.Wax:
      return `https://waxblock.io/transaction/${escapedHash}`;
    case DataSourceCode.Acala:
      return `https://acala.subscan.io/extrinsic/${escapedHash}`;
    case DataSourceCode.Qoin:
      // the user must be logged in to a private portal to go to the link,
      // so it's better to not show.
      return undefined;
    default:
      return undefined;
  }
};
